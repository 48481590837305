/* Header.css */
div {
  padding: 10px;
  margin: 0;
  text-align: center; /* Add this line */
}
  
a {
  margin: 10px;
  text-decoration: none;
  color: #333;
}

a:hover {
  color: #0073e6;
}

.header-link {
  margin: 10px;
}