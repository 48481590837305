.App {
  text-align: left;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}

@media (max-width: 599px) {
  .App {
    font-size: 16px;
  }
  ul {
    padding-left: 20px;
  }
  a {
    margin: 0px;
  }
}

@media (min-width: 600px){
  .App {
    font-size: 18px;
  }
}
